<template>
  <div class="popup">
    <sweet-modal ref="popupModal" overlay-theme="dark" @close="closeModal" :enableMobileFullscreen="false">
      <div v-if="popup && popup.length===1" class="img-popup" :style="bgCoverStyle(popup[0].url)" @click="clickPopup"></div>
      <div class="full-width position-relative" v-if="popup && popup.length>1">
        <image-slider ref="slider"
          :listData="popup" :height="height" :autoplay="true"
          :indicator="true" :imageRates="{ratio: '3:4'}"
          @reRender="$refs.popupModal.open()"></image-slider>

        <div class="unselect button-arrow button-arrow-left" @click="$refs.slider.$refs.slick.prev()">
          <i class="material-icons">keyboard_arrow_left</i>
        </div>
        <div class="unselect button-arrow button-arrow-right" @click="$refs.slider.$refs.slick.next()">
          <i class="material-icons">keyboard_arrow_right</i>
        </div>
      </div>
      <div v-if="vPopup" class="flex" style="background-color: white;overflow: hidden;">
        <div class="close-area size-14 unselect sub"
            @click="offToday">오늘 그만 보기</div>
        <div class="close-area size-14 unselect primary"
            @click="offPopup">닫기</div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
  import { SweetModal } from 'sweet-modal-vue'
  import ImageSlider from "../module/ImageSlider";

  export default {
    name: "PopupNotice",
    components: {
      ImageSlider,
      SweetModal,

    },
    data() {
      return {
        vPopup: false,
        popup: [],
        ratio: 0,
        height: 0,
        usePopup: false,
      }
    },
    created() {
      this.getPopupList();
      this.height = window.innerWidth <= 500 ? 380 : 500;
    },
    methods: {
      bgCoverStyle(url) {
        return {
          backgroundImage: `url(${url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }
      },
      getPopupList() {
        this.$axios.get('public/popup').then(res=>{
          this.popup = res.data.data;
          this.ratio = res.data.ratio;
          if (this.popup.length !== 0) {
            let today = this.dayjs().format('YY-DD-MM');
            let today2 = this.dayjs().format('YYYY-MM-DD');
            if (today2 < this.popup[0].start_date || today2 > this.popup[0].end_date) {
              this.usePopup = false;
            } else if (this.$store.getters.exposuredPopup !== true) {
              this.usePopup = true;
            } else if (this.$store.getters.exposuredPopup) {
              if(!this.$store.getters.hidePopup || this.$store.getters.hidePopup !== today) {
                this.usePopup = true;
              }
            } else {
              this.usePopup = false;
            }

            if (this.usePopup) {
              this.$refs.popupModal.open();
              setTimeout(() => {
                this.vPopup = true;
              }, 520);
            }
          }
        })
      },
      clickPopup() {
        let link = this.popup[0].link;
        if(link) {
          if (link.indexOf('http:') > -1 || link.indexOf('https:') > -1) {
            if (link.indexOf(location.origin) > -1) {
              this.routerPush(link.replace(location.origin, ''));
            } else {
              const a = document.createElement("a");
              a.href = link;
              a.setAttribute('target', '_blank');
              a.style.display = 'none';
              document.body.appendChild(a);
              a.click();
              a.parentNode.removeChild(a);
            }
          } else {
            // location.href = link;
            this.routerPush(link);
          }
        }
      },
      offToday() {
        let today = this.dayjs().format('YY-DD-MM');
        this.$store.commit('setHidePopup', today);
        this.$store.commit('setExposuredPopup', true);
        this.$refs.popupModal.close();
      },
      offPopup() {
        this.$store.commit('setExposuredPopup', true);
        this.$refs.popupModal.close();
      },
      closeModal() {
        this.$store.commit('setExposuredPopup', true);
      }
    }
  }
</script>

<style lang="stylus" type="stylus" scoped>
  @import '~assets/css/lp_main'
  .close-area
    width 50%
    padding 12px
    text-align center

  .img-popup
    height 500px

  @media screen and (max-width 500px)
    .img-popup
      height 380px

  .button-arrow
    position absolute
    top calc(50% - 22px)
    width 40px
    height 44px
    background-color rgba(0,0,0,0.3)
    display flex
    align-items center
    justify-content center
    i
      font-size 32px
      color white
  .button-arrow-right
    right 0
</style>
<style lang="stylus">
  .popup
    .sweet-modal
      background-color transparent
    .sweet-modal.is-alert .sweet-content
      padding 0 !important
    .sweet-modal.is-alert
      width 420px !important
      overflow hidden
    .sweet-modal-overlay.is-visible
      z-index 100000000
  @media screen and (max-width: 500px)
    .popup
      .sweet-modal.is-alert
        width 320px !important
      .sweet-modal .sweet-box-actions
        top 4px
        right 4px

</style>
